<template>
  <div class="page">
    <v-container>
      <h1>Risultati ricerca</h1>
      <div>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            v-for="post in postList"
            :key="post.id"
          >
            <!-- <v-card
              outlined
              class="fill-height my-2 rounded-md d-flex flex-column"
              :to="{
                name: 'NewsDetail',
                params: { postName: post.slug }
              }"
            >
              <v-img
                height="250"
                max-height="250"
                :src="post.featured_image_url"
                :alt="`Immagine: ${post.title}`"
              ></v-img>
              <v-card-subtitle class="py-0" v-if="post.type === 'post'">
                <time>{{ $dayjs(post.date).format("LL") }} </time>
              </v-card-subtitle>
              <v-card-title
                class="card-description pa-3 body-1 font-weight-bold d-flex align-start"
                >{{ post.title }}</v-card-title
              >
              <v-card-text class="post-content card-description-secondary">
                {{ post.exerpt }}
              </v-card-text>
            </v-card> -->
            <search-card :post="post" />
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>
<script>
import cmService from "~/service/cmService";
import clickHandler from "~/mixins/clickHandler";
import page from "~/mixins/page";
import SearchCard from "@/components/wordpress/SearchCard.vue";

export default {
  components: { SearchCard },
  mixins: [clickHandler, page],
  //subtypes
  //subtype: è uno di post, page, blocks, recipe, wpsl_stores, faq, produttore, richiamo_prodotto, we_love_trees, category, post_tag, ingredient, course, cuisine, wpsl_store_category, categoria_faq, categoria_wlt, chefs, provincia, e any." }
  data() {
    return {
      query: null,
      subtype: [
        "post",
        "produttore",
        "recipe",
        "we_love_trees",
        "faq",
        "richiamo_prodotto"
      ],
      page: 1,
      postList: []
    };
  },
  methods: {
    async reload() {
      cmService.search(this.query, "recipe", this.page, 24).then(res => {
        this.postList = res.data;
      });
    }
  },
  created() {
    this.query = this.$route.query.q;
    if (this.$route.query.subtype) {
      this.subtype = this.$route.query.subtype;
    }
    this.reload();
  },
  watch: {
    "$route.params.q": function(value) {
      this.query = value;
      this.reload();
    }
  }
};
</script>
