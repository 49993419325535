<template>
  <v-card
    outlined
    class="fill-height my-2 rounded-md d-flex flex-column post-card"
    :to="{
      name: routeName,
      params: { postName: post.slug }
    }"
  >
    <v-img
      height="250"
      max-height="250"
      :src="post.featured_image_url"
      :alt="`Immagine: ${post.title}`"
    ></v-img>
    <v-card-subtitle class="py-0" v-if="post.type === 'post'">
      <time>{{ $dayjs(post.date).format("LL") }} </time>
    </v-card-subtitle>
    <v-card-title
      class="card-description pa-3 body-1 font-weight-bold d-flex align-start"
      >{{ post.title }}</v-card-title
    >
    <v-card-text class="post-content card-description-secondary">
      {{ post.exerpt }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "PostCard",
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  methods: {},
  routeName() {
    switch (this.post.subtype) {
      case "post":
        return "NewsDetail";
      case "approfondimento":
        return "ApprofondimentoDetail";
      case "video":
        return "VideoDetail";
      case "recipe":
        return "AppuntinoDetail";
      case "appuntino":
        return "AppuntinoDetail";
      case "mixology":
        return "MixologyDetail";
      case "faq":
        return "FaqiList";
      default:
        return "Page";
    }
  },
  computed: {
    routeName() {
      switch (this.post.subtype) {
        case "post":
          return "NewsDetail";
        case "approfondimento":
          return "NewsDetail";
        case "video":
          return "VideoDetail";
        case "recipe":
          return "RecipeDetail";
        case "appuntino":
          return "RecipeDetail";
        case "mixology":
          return "RecipeDetail";
        case "faq":
          return "FaqiList";
        default:
          return "Page";
      }
    }
  }
};
</script>
